.imgSuperior {
  height: 220px;
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.6)
    ),
    url("../Imagenes/CasasEco/obrasHome.webp");
  background-color: transparent;
  background-size: cover;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 25px;
  justify-content: center;
  text-align: center;
}
.projectsSpaceCasas {
  display: flex;
  justify-content: space-around;
  align-content: center;
  flex-wrap: wrap;
  margin-bottom: 2%;
}
.casasTerminadas {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-content: center;
  flex-wrap: wrap;
  margin-top: 5%;
}

.casasTerminadas h3 {
  width: 100%;
  text-align: center;
  margin-top: 2%;
}
.casasTerminadas p {
  width: 95%;
  text-align: center;
  margin-top: 5%;
}
.sistema-Constructivo p {
  margin-top: 10%;
}
.proyectoCasas {
  width: 45%;
  text-align: center;
  margin-top: 1%;
}
.sistema-Constructivo {
  width: 45%;
  justify-content: space-around;
  align-content: center;
  flex-wrap: wrap;
  margin-top: 2%;
  text-align: center;
}

.casasImg1,
.casasImg2,
.casasImg3,
.casasImg4,
.casasImg5 {
  background-size: cover;
  height: 400px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}
.casasImg1 {
  background-image: url("../Imagenes/CasasEco/1moderna.jpg");
}
.casasImg2 {
  background-image: url("../Imagenes/CasasEco/1rustica.jpg");
}
.casasImg3 {
  background-image: url("../Imagenes/CasasEco/2estrucAcero.jpg");
}
.casasImg4 {
  background-image: url("../Imagenes/CasasEco/3ForjSanit.jpg");
}

@media only screen and (max-device-width: 370px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  /* .projectsSpace p {
    font-size: smaller;
  }*/
  .projectsSpaceCasas {
    display: block;
  }
  .projectsSpaceCasas p {
    font-size: 14px;
  }
  .proyectoCasas {
    width: 84%;
    height: 250px;
    /*margin-bottom: 30px;*/
  }
  .sistema-Constructivo {
    width: 84%;
    margin: 27px;
  }
  .casasTerminadas h3,
  .casasTerminadas p {
    width: 84%;
    font-size: 14px;
  }
  .casasImg1,
  .casasImg2,
  .casasImg3,
  .casasImg4 {
    height: 250px;
    margin-bottom: 30px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 430px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  /* .projectsSpace p {
    font-size: smaller;
  }*/
  .projectsSpaceCasas {
    display: block;
  }
  .projectsSpaceCasas p {
    font-size: 14px;
  }
  .proyectoCasas {
    width: 84%;
    height: 250px;
    /*margin-bottom: 30px;*/
  }
  .sistema-Constructivo {
    width: 84%;
    margin: 27px;
  }
  .casasTerminadas h3,
  .casasTerminadas p {
    width: 84%;
  }

  .casasImg1,
  .casasImg2,
  .casasImg3,
  .casasImg4 {
    height: 250px;
    margin-bottom: 30px;
  }
}
