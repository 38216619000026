.phone,
.mail,
.position {
  display: flex;
  justify-content: center;
  align-items: center;
}
.phone,
.position {
  width: 33%;
}

/*.position {
  margin-right: 25px;
}*/

.phone a,
.mail a,
.position p {
  text-decoration: none;
  color: var(--myWhite);
  font-size: 18px;
}
@media only screen and (max-device-width: 370px) and (orientation: portrait) {
  .cabeceraSuperior svg {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
  .phone a,
  .mail a,
  .position p {
    font-size: 12px;
  }

  .phone {
    margin-left: 1%;
    margin-right: 10px;
  }
  .phone,
  .mail {
    margin-top: 10px;
  }
  .position {
    width: 100%;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 430px) and (orientation: portrait) {
  .cabeceraSuperior svg {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
  .phone a,
  .mail a,
  .position p {
    font-size: 12px;
  }

  .phone {
    margin-left: 1%;
    margin-right: 30px;
  }
  .phone,
  .mail {
    margin-top: 10px;
  }
  .position {
    width: 100%;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 430px) and (orientation: portrait) {
  .cabeceraSuperior svg {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
  .phone a,
  .mail a,
  .position p {
    font-size: 12px;
  }

  .phone {
    margin-left: 1%;
    margin-right: 30px;
  }
  .phone,
  .mail {
    margin-top: 10px;
  }
  .position {
    width: 100%;
  }
}
@media only screen and (min-device-width: 430px) and (max-device-width: 720px) and (orientation: portrait) {
  .cabeceraSuperior svg {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
  .phone a,
  .mail a,
  .position p {
    font-size: 12px;
  }

  .phone {
    margin-left: 1%;
    margin-right: 30px;
  }
  .phone,
  .mail {
    margin-top: 10px;
  }
  .position {
    width: 100%;
  }
}
@media only screen and (min-device-width: 720px) and (max-device-width: 926px) and (orientation: portrait) {
  .phone a,
  .mail a,
  .position p {
    font-size: 16px;
  }

  .phone {
    margin-left: 1%;
    margin-right: 30px;
  }
  .phone,
  .mail {
    margin-top: 10px;
  }
  .position {
    width: 100%;
  }
}

/*mobile Landscape */
@media only screen and (max-device-width: 640px) and (orientation: landscape) {
  .phone a,
  .mail a,
  .position p {
    font-size: 12px;
  }

  .phone {
    margin-left: 1%;
    margin-right: 30px;
  }
  .phone,
  .mail {
    margin-top: 10px;
  }
  .position {
    width: 100%;
  }
}
@media only screen and (min-device-width: 640px) and (max-device-width: 667px) and (orientation: landscape) {
  .phone a,
  .mail a,
  .position p {
    font-size: 12px;
  }

  .phone {
    margin-left: 1%;
    margin-right: 30px;
  }
  .phone,
  .mail {
    margin-top: 10px;
  }
  .position {
    width: 100%;
  }
}
@media only screen and (min-device-width: 667px) and (max-device-width: 928px) and (orientation: landscape) {
  .phone a,
  .mail a,
  .position p {
    font-size: 14px;
  }

  .phone {
    margin-right: 30px;
  }
  .phone,
  .mail {
    margin-top: 10px;
  }
  .position {
    margin-right: 10px;
    margin-left: 10px;
  }
}
