body {
  margin: 0;
  font-family: "Roboto Condensed", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto Condensed", sans-serif;
}
:root {
  --myLowBlue: #b3e0f2;
  --myOrange: #fe5000;
  --myGreen: #7dbe16;
  --myGrey: #52616b;
  --myWine: #6b5352;
  --myYellow: #ebea7c;
  --myWhite: #ebeff2;
  --myGreenGrey: #6b6b52;
  --myBlue: #86a9c0;
  /*--myPureWhite: #ffffff;*/
}
