.contenedor-general-carrousel {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.contenedor-carrousel {
  width: 100%;
}
.contenedor-imagenes {
  max-height: max-content;
  height: 351px;
  text-align: center;
  justify-content: center;
}

.imagen-principal {
  width: 100%;
  height: 100%;
  margin: auto;
  object-fit: cover;
  opacity: 50%;
}
.texto-imagen {
  width: 100%;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 40px;
  font-weight: 600;
  animation-name: sacarTexto;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-play-state: running;
  text-shadow: 0px 10px 10px rgb(229, 235, 247, 0.25);
}

.texto-imagen::after {
  font-size: 48px;
  font-weight: 600;
  transition-property: font-size;
  transition-duration: 3s;
  transition-delay: 2s;
}

.texto-Home {
  width: 85%;
  text-align: center;
  margin-top: 50px;
}
.texto-Home h2 {
  font-size: 26px;
}
.contenedor-proyectos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
}
.contenedor-proyectos-reformas-descripcion,
.contenedor-proyectos-casas-descripcion {
  display: flex;
  justify-content: space-around;
  align-content: center;
  flex-wrap: wrap;
  margin-bottom: 2%;
}
.contenedor-reformas,
.contenedor-steelframe {
  width: 50%;
  margin: 30px;
}

.contenedor-proyectos-reformas-textos {
  margin-right: 30px;
  display: block;
  width: 40%;
  text-align: justify;
}
.contenedor-proyectos-casas-textos {
  margin-left: 30px;
  display: block;
  width: 40%;
  text-align: justify;
}

.reformas-home,
.steelframe-home {
  width: 100%;
  height: 300px;
  object-fit: cover;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
}

@keyframes transicionImagenes {
  from {
    left: 0px;
  }
  to {
    left: 100px;
  }
}
/*mobile Portrait */
@media only screen and (max-device-width: 375px) and (orientation: portrait) {
  .contenedor-imagenes {
    height: 200px;
  }
  .texto-imagen {
    top: 52%;
    font-size: 15px;
  }
  .texto-Home h2 {
    font-size: 15px;
  }
  .contenedor-proyectos p {
    font-size: smaller;
  }
  .contenedor-reformas,
  .contenedor-steelframe {
    width: 100%;
    margin: 30px;
  }
  .contenedor-proyectos {
    display: block;
  }
  .reformas-home,
  .steelframe-home {
    width: 84%;
    height: 150px;
    margin-bottom: 30px;
  }
  .contenedor-proyectos-reformas-descripcion {
    display: flex;
    flex-flow: column-reverse;
  }

  .contenedor-proyectos-casas-descripcion {
    display: flex;
    flex-flow: column;
  }
  .contenedor-proyectos-reformas-textos,
  .contenedor-proyectos-casas-textos {
    width: 85%;
    text-align: center;
  }
  .contenedor-proyectos-reformas-textos {
    margin-left: 30px;
  }
}

/*mobile Portrait */
@media only screen and (min-device-width: 376px) and (max-device-width: 720px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .contenedor-imagenes {
    height: 200px;
  }
  .texto-imagen {
    top: 35%;
    font-size: 18px;
  }
  .texto-Home h2 {
    font-size: 16px;
  }

  .contenedor-proyectos p {
    font-size: smaller;
  }
  .contenedor-reformas,
  .contenedor-steelframe {
    width: 100%;
    margin: 30px;
  }
  .reformas-home,
  .steelframe-home {
    width: 84%;
    height: 150px;
    margin-bottom: 30px;
  }

  .contenedor-proyectos-reformas-descripcion {
    display: flex;
    flex-flow: column-reverse;
  }

  .contenedor-proyectos-casas-descripcion {
    display: flex;
    flex-flow: column;
  }
  .contenedor-proyectos-reformas-textos,
  .contenedor-proyectos-casas-textos {
    width: 85%;
    text-align: center;
  }
  .contenedor-proyectos-reformas-textos {
    margin-left: 30px;
  }
}

@media only screen and (min-device-width: 721px) and (max-device-width: 926px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .texto-imagen {
    top: 25%;
    font-size: 30px;
  }
  .contenedor-imagenes {
    height: 200px;
  }
  .texto-Home h2 {
    font-size: 16px;
  }
  .contenedor-proyectos p {
    font-size: smaller;
  }
  .contenedor-reformas,
  .contenedor-steelframe {
    width: 100%;
    margin: 30px;
  }
  .contenedor-proyectos {
    display: block;
    margin-left: 40px;
  }
  .reformas-home,
  .steelframe-home {
    width: 84%;
    height: 396px;
    margin-bottom: 30px;
  }
  .contenedor-proyectos-reformas-descripcion {
    display: flex;
    flex-flow: column-reverse;
  }

  .contenedor-proyectos-casas-descripcion {
    display: flex;
    flex-flow: column;
  }
  .contenedor-proyectos-reformas-textos,
  .contenedor-proyectos-casas-textos {
    width: 85%;
    text-align: center;
    margin-left: 27px;
  }
}
@media only screen and (min-device-width: 927px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .texto-imagen {
    top: 20%;
    font-size: 30px;
  }
  .contenedor-imagenes {
    height: 200px;
  }
  .texto-Home h2 {
    font-size: 16px;
  }
  .contenedor-proyectos p {
    font-size: smaller;
  }
  .contenedor-reformas,
  .contenedor-steelframe {
    width: 100%;
    margin: 30px;
  }
  .contenedor-proyectos {
    display: block;
    margin-left: 40px;
  }
  .reformas-home,
  .steelframe-home {
    width: 84%;
    height: 396px;
    margin-bottom: 30px;
  }
  .contenedor-proyectos-reformas-descripcion {
    display: flex;
    flex-flow: column-reverse;
  }

  .contenedor-proyectos-casas-descripcion {
    display: flex;
    flex-flow: column;
  }
  .contenedor-proyectos-reformas-textos,
  .contenedor-proyectos-casas-textos {
    width: 85%;
    text-align: center;
    margin-left: 27px;
  }
}
/*mobile Landscape */
@media only screen and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .contenedor-imagenes {
    height: 200px;
  }
  .texto-imagen {
    top: 90%;
    font-size: 16px;
  }
  .texto-Home h2 {
    font-size: 16px;
  }

  .contenedor-proyectos p {
    font-size: smaller;
  }
  .contenedor-reformas,
  .contenedor-steelframe {
    width: 100%;
    margin: 30px;
  }
  .reformas-home,
  .steelframe-home {
    width: 84%;
    height: 360px;
    margin-bottom: 30px;
  }

  .contenedor-proyectos-reformas-descripcion {
    display: flex;
    flex-flow: column-reverse;
  }

  .contenedor-proyectos-casas-descripcion {
    display: flex;
    flex-flow: column;
  }
  .contenedor-proyectos-reformas-textos,
  .contenedor-proyectos-casas-textos {
    width: 85%;
    text-align: center;
  }
  .contenedor-proyectos-reformas-textos {
    margin-left: 30px;
  }
}
@media only screen and (min-device-width: 641px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .contenedor-imagenes {
    height: 200px;
  }
  .texto-imagen {
    top: 80%;
    font-size: 18px;
  }
  .texto-Home h2 {
    font-size: 16px;
  }

  .contenedor-proyectos p {
    font-size: smaller;
  }
  .contenedor-reformas,
  .contenedor-steelframe {
    width: 100%;
    margin: 30px;
  }
  .reformas-home,
  .steelframe-home {
    width: 84%;
    height: 360px;
    margin-bottom: 30px;
  }

  .contenedor-proyectos-reformas-descripcion {
    display: flex;
    flex-flow: column-reverse;
  }

  .contenedor-proyectos-casas-descripcion {
    display: flex;
    flex-flow: column;
  }
  .contenedor-proyectos-reformas-textos,
  .contenedor-proyectos-casas-textos {
    width: 85%;
    text-align: center;
  }
  .contenedor-proyectos-reformas-textos {
    margin-left: 30px;
  }
}
@media only screen and (min-device-width: 668px) and (max-device-width: 928px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .contenedor-imagenes {
    height: 200px;
  }
  .texto-imagen {
    top: 80%;
    font-size: 18px;
  }
  .texto-Home h2 {
    font-size: 16px;
  }
  .contenedor-proyectos {
    margin-left: 30px;
  }
  .contenedor-proyectos p {
    font-size: smaller;
  }
  .contenedor-reformas,
  .contenedor-steelframe {
    width: 100%;
    margin: 30px;
  }
  .reformas-home,
  .steelframe-home {
    width: 86%;
    height: 360px;
  }

  .contenedor-proyectos-reformas-descripcion {
    display: flex;
    flex-flow: column-reverse;
  }

  .contenedor-proyectos-casas-descripcion {
    display: flex;
    flex-flow: column;
    margin-left: 30px;
  }
  .contenedor-proyectos-reformas-textos,
  .contenedor-proyectos-casas-textos {
    width: 85%;
    text-align: center;
  }
  .contenedor-proyectos-reformas-textos {
    margin-left: 30px;
  }
}
