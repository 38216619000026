.projectsSpace {
  display: flex;
  justify-content: space-around;
  align-content: center;
  flex-wrap: wrap;
  margin-bottom: 2%;
}
.projectVideoPresentacion {
  margin-top: 50px;
  margin-bottom: 50px;
}
.videoPresentacion {
  border: none;
}

.projecto1 {
  width: 45%;
  margin-top: 5%;
  margin-left: 2%;
  margin-right: 2%;
  text-align: center;
}
.projecto1 p {
  margin-top: 10%;
}

.proyectoImg01 {
  /*margin-top: 5%;
  margin-bottom: 5%;*/
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  /*background-size: cover;
  height: 400px;*/
}
.proyectoImg04 {
  margin-top: 5%;
  margin-bottom: 5%;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  background-size: cover;
  height: 400px;
}

.proyectoImg04 {
  background-image: url("../Imagenes/Proyectos/Igualada/igualada01.webp");
}

@media only screen and (max-device-width: 375px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .projectsSpace {
    display: block;
    margin-left: 12%git;
  }
  .projectsSpace p {
    font-size: 14px;
  }
  .projectVideoPresentacion {
    margin-top: 10%;
  }
  .videoPresentacion {
    height: 250px;
    width: 100%;
    margin-bottom: 30px;
  }
  .projecto1,
  .projecto2,
  .projecto3,
  .projecto4,
  .projecto5,
  .projecto6 {
    width: 84%;
    margin-top: 10%;
  }
  .proyectoImg01,
  .proyectoImg02,
  .proyectoImg03,
  .proyectoImg04,
  .proyectoImg05,
  .proyectoImg06,
  .proyectoImg07 {
    height: 250px;
    margin-bottom: 30px;
  }
}
@media only screen and (min-device-width: 376px) and (max-device-width: 720px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .projecto1,
  .projecto2,
  .projecto3,
  .projecto4,
  .projecto5,
  .projecto6 {
    width: 84%;
    margin-top: 10%;
  }
  .proyectoImg01,
  .proyectoImg02,
  .proyectoImg03,
  .proyectoImg04,
  .proyectoImg05,
  .proyectoImg06,
  .proyectoImg07 {
    height: 250px;
    margin-bottom: 30px;
  }
  .projectVideoPresentacion {
    margin-top: 10%;
  }
  .videoPresentacion {
    height: 250px;
    width: 100%;
    margin-bottom: 30px;
  }
}
@media only screen and (min-device-width: 721px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .projectVideoPresentacion {
    margin-top: 10%;
  }
  .videoPresentacion {
    height: 250px;
    width: auto;
    margin-bottom: 30px;
  }
}
@media only screen and (max-device-width: 374px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .projectVideoPresentacion {
    margin-top: 10%;
  }
  .videoPresentacion {
    height: 250px;
    width: auto;
    margin-bottom: 30px;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 720px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .projectVideoPresentacion {
    margin-top: 10%;
  }
  .videoPresentacion {
    height: 250px;
    width: auto;
    margin-bottom: 30px;
  }
  .proyectoImg04 {
    height: 286px;
  }
}
@media only screen and (min-device-width: 721px) and (max-device-width: 932px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .projectVideoPresentacion {
    margin-top: 10%;
  }
  .videoPresentacion {
    height: 250px;
    width: auto;
    margin-bottom: 30px;
  }
  .proyectoImg04 {
    height: 286px;
  }
}
